import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { Chip, Dialog, DialogContent, IconButton, Snackbar, Tooltip } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import { createStyles, StyleRules, Theme, WithStyles, withStyles } from '@material-ui/core/styles';
import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder';
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';
import EmojiPeopleSharpIcon from '@material-ui/icons/EmojiPeopleSharp';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ReplyIcon from '@material-ui/icons/Reply';
import ShareIcon from '@material-ui/icons/Share';
import { Alert } from '@material-ui/lab';
import { navigate } from 'gatsby';

import { consultantGetAllProjectBidOnlyId } from '../actions/projectBidAction';
import { anonymousGetProjectRequestById } from '../actions/projectRequestAction';
import picture from '../images/fintegrity-logo.jpeg';
import { AppState } from '../reducers';
import { FUserTypeEnum } from '../reducers/userReducer';
import CertificateTypeArray from '../utils/CertificateType';
import { companyIndustry } from '../utils/companyInfo';
import { formatDate } from '../utils/dateFormat';
import { educationBackgroundRequirements } from '../utils/educationBackground';
import Industry from '../utils/industry';
import { industryFilterHandle } from '../utils/industryFilter';
import LANGUAGE_WORLD from '../utils/languageType';
import { moneyUnit } from '../utils/projectView';
import { searchLabelsByValue } from '../utils/searchUtils';
import { Seniority } from '../utils/seniority';
import Professional from '../utils/special';
import { MatchArea } from '../utils/util';
import ConsultantAppliesBiddingView from '../views/consultant/consultantAppliesBiddingView';
import DialogTitles from './DialogTitle';
import LoadingLogo from './LoadingLogo';

const styles = (theme: Theme): StyleRules =>
  createStyles({
    contentBox: {
      background: '#fff',
      [theme.breakpoints.up('sm')]: {
        // 当屏幕宽度 >= 600px 时应用的样式
        width: '100%',
        margin: '2rem auto',
      },
      [theme.breakpoints.up('md')]: {
        // 当屏幕宽度 >= 960px 时应用的样式
        width: '55vw',
        maxWidth: '55rem',
        margin: '2rem auto',
      },
      padding: '2rem',
      borderRadius: '5px',
      fontSize: '1rem',
      color: '#333',
      position: 'relative',
    },
    companyStyle: {
      margin: '1rem 0',
      borderRadius: '5px',
      boxShadow: ' 0 0 2px 2px rgba(32, 35, 42, 0.5)',
      backgroundColor: '#ffffff',
      [theme.breakpoints.up('md')]: {
        position: 'absolute',
        right: '-21vw',
        top: '5rem',
        width: '20vw',
        maxWidth: '20rem',
      },
    },
    remunerationBoxOne: {
      padding: '1rem',
      color: '#fff',
      backgroundColor: '#BB8FCE',
    },
    center: {
      textAlign: 'center',
    },
    Share: {
      position: 'fixed',
      right: '1rem',
      top: '100px',
    },

    inlineBox: {
      whiteSpace: 'normal',
      wordWrap: 'break-word',
    },

    message: {
      whiteSpace: 'normal',
    },
    back: {
      position: 'fixed',
      right: '0',
      bottom: '20px',
      width: '10rem',
      alignItems: 'center',
    },
    companyBox: {
      padding: '1rem',
      [theme.breakpoints.up('sm')]: {
        // 当屏幕宽度 >= 600px 时应用的样式
        // width: '100%',
        // margin: '2rem auto',
      },
      [theme.breakpoints.up('md')]: {
        borderRadius: '5px',
        boxShadow: ' 0 0 5px 5px rgba(32, 35, 42, 0.5)',
        backgroundColor: 'rgba(255, 255, 255, 0.5)',
        position: 'fixed',
        right: '1rem',
        top: '30%',
      },
    },
    projectName: {
      width: '35rem',
      whiteSpace: 'normal',
    },
    fontsIcon: {
      display: 'inline-block',
      height: '1rem',
      width: '1rem',
      borderRadius: '50%',
      marginTop: '0.2rem',
      marginRight: '0.5rem',
      boxShadow: '0 0 0.5rem rgba(32, 35, 42, 0.5)',
    },
    textContent: {
      display: 'flex',
      flexWrap: 'wrap',
      lineHeight: '1.5rem',
      marginTop: '1rem',
      whiteSpace: 'normal',
      wordBreak: 'break-word',
    },

    textContent2: {
      color: 'rgba(0, 0, 0, 0.5)',
      padding: '1rem',
      display: 'flex',
      flexWrap: 'wrap',
      lineHeight: '1.8rem',
      whiteSpace: 'normal',
      wordBreak: 'break-word',
    },

    displayFlex: {
      display: 'flex',
    },
    remunerationInfo: {
      padding: '0 1rem',
      lineHeight: '1.5rem',
    },
    languageInfo: {
      padding: '0 1rem',
    },
    borderBottom: {
      color: 'rgba(0, 0, 0, 0.5)',
      padding: '0.3rem 0',
      fontWeight: 'bold',
    },
    textAlignCenter: {
      textAlign: 'center',
    },
    examine: {
      textAlign: 'center',
    },
    projectTitle: {
      marginTop: '1rem',
      color: 'inherit',
      fontWeight: 'bold',
      textRendering: 'optimizeLegibility',
      lineHeight: '1.1',
      fontSize: '1.58316rem',
      display: 'flex',
      justifyContent: 'space-between',
      [theme.breakpoints.up('sm')]: {
        marginBottom: '0.5rem',
      },
    },
    divLevel2: {
      color: '#333',
      display: 'flex',
      textRendering: 'optimizeLegibility',
      lineHeight: '1.1',
      alignItems: 'center',
      fontSize: '1.1rem',
      [theme.breakpoints.up('sm')]: {
        marginBottom: '2rem',
      },
    },
    companyDiv: {
      padding: '1rem',
      color: '#666666',
    },
    chip: {
      margin: '0.5rem',
    },
    chipTwo: {
      color: '#ffffff',
      margin: '0.5rem',
    },
    sideTitle: {
      fontSize: '1.3rem',
      fontWeight: 'bold',
    },
    dialogWidth: {
      marginTop: '64px',
      padding: '0',
      width: '100%',
    },
    bidingView: {
      [theme.breakpoints.up('md')]: {
        width: '80%',
      },
      margin: '0 auto',
      scrollbarWidth: 'none',
    },
    spanColor: {
      color: 'rgba(0, 0, 0, 0.5)',
      margin: '0 1rem',
    },
    content: {
      margin: '1rem 0',
      whiteSpace: 'normal',
      wordBreak: 'break-word',
    },
    wordContent: {
      width: '80%',
      margin: '1rem 0',
      color: '#666666',
      lineHeight: '1.5rem',
      paddingLeft: '2rem',
      fontSize: '0.85rem',
      '& > div': {
        margin: '0.5rem 0',
      },
    },
    avatarPicture: {
      border: '1px solid #e0e0e0',
      borderRadius: '50%',
      height: '4rem',
      width: '4rem',
      margin: '0 0.5rem',
    },
    avatarPictureBox: {
      width: '100%',
      height: '4rem',
      paddingLeft: '1rem',
      alignItems: 'center',
      display: 'flex',
      margin: '0.5rem 0',
    },
    annexFileCss: {
      display: 'flex',
      alignItems: 'center',
      margin: '0.3rem',
      padding: '0.3rem',
      color: 'black',
      borderBottom: '1px solid #f5f5f9',
    },
    companyTitle: {
      fontSize: '1.5rem',
      color: 'black',
      fontWeight: 'bold',
    },
    projectContent: {
      marginLeft: '1rem',
    },
    bgColor: {
      margin: '0 1rem',
      '&:hover': {
        backgroundColor: '#9b9bbe',
        color: '#fff',
      },
    },
    dLine: { margin: '1rem 0rem' },
    projectSalary: {
      width: '10.5rem',
      letterSpacing: '0.3rem',
      color: '#fe574a',
      fontSize: '1.5rem',
      fontWeight: 'bold',
    },
    parallel: {
      display: 'flex',
      alignItems: 'center',
    },
    secondaryTitle: {
      fontWeight: 'bold',
      width: '6rem',
    },
    secondaryContent: {
      display: 'flex',
    },
    workAddress: {
      color: '#666666',
      fontSize: '0.85rem',
      paddingLeft: '2rem',
      wordWrap: 'break-word',
    },
    buttonClass: {
      margin: '1rem 0',
    },
  });
const ProjectPreviewView = ({ requestId, classes }: Props): ReactElement => {
  // const { t } = useTranslation();
  // i18n.loadNamespaces('ProjectPreviewView');
  const dispatch = useDispatch();
  const user = useSelector((appState: AppState) => appState.user);
  const [projectData, setProjectData] = useState<ProjectRequestInfo>();
  const [industryLabelList, setIndustryLabelList] = useState([]);
  const [professionalLabelList, setProfessionalLabelList] = useState<string[]>([]);
  const [workScenario, setWorkScenario] = useState<workScenarioSelect>();
  const [showLoading, setShowLoading] = useState(true);
  const [alreadyBid, setAlreadyBid] = useState<boolean>(false);
  const [bidAllRequestId, setBidAllRequestId] = useState([]);

  const [dialogOpen, setDialogOpen] = React.useState(false);
  const Proficiency_level = {
    Beginner: '初级',
    Intermediate: '中级',
    Advanced: '中级',
    Proficiency: '精通',
  };
  const [open, setOpen] = React.useState(false);
  const [shareMessage, setShareMessage] = useState('');

  const handleClose = () => {
    setOpen(false);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const formatName = (el: DescriptionFile) => {
    return el.file ? `${el.file.split(';')[0].split(':')[1]}` : null;
  };

  const resultProfessional = (professionalList: string): string[] => {
    const data = JSON.parse(professionalList);
    const valuesToSearch = data.map((subArray: []) => subArray[subArray.length - 1]);
    const zhi = searchLabelsByValue(Professional, valuesToSearch);
    return zhi;
  };

  const handleApply = useCallback(async () => {
    if (!user?.id) {
      const redirect = `/projectRequest/projectPreview/#${requestId}`;
      navigate(`/consultant/login?redirect=${encodeURIComponent(redirect)}`);
    } else {
      if (user.consultant?.id) {
        setDialogOpen(true);
      }
    }
  }, [user?.id]);

  const fetchData = async () => {
    if (requestId) {
      const data = await dispatch(anonymousGetProjectRequestById(requestId));
      setProjectData(data);
      setShowLoading(false);
      if (data?.industry) {
        const industryLabel = industryFilterHandle(JSON.parse(data?.industry as string), Industry);
        setIndustryLabelList(industryLabel);
      }
      if (data?.professionalClass) {
        const professionalLabel = resultProfessional(data?.professionalClass);
        setProfessionalLabelList(professionalLabel);
      }

      if (data?.workScenario) {
        const value = JSON.parse(String(data?.workScenario));
        setWorkScenario(value);
      }
    }
  };

  const copyToClipboard = async () => {
    const currentURLData = window.location.href;
    const locationArray = currentURLData.split('/');
    const siteURl =
      locationArray[0] +
      '//' +
      locationArray[2] +
      '/projectRequest' +
      '/anyOneProjectPreview' +
      '/' +
      locationArray[locationArray.length - 1];
    try {
      if (navigator.clipboard) {
        await window.navigator.clipboard.writeText(siteURl);
        setShareMessage(`已复制链接`);
        setOpen(true);
      } else {
        const el = document.createElement('textarea');
        el.value = siteURl;

        document.body.appendChild(el);
        el.focus();
        el.select();

        try {
          const successful = document.execCommand('copy');
          successful ? setShareMessage(`已复制链接`) : setShareMessage(`无法复制链接！`);
          setOpen(true);
        } catch (err) {
          setShareMessage(`无法复制链接！`);
          setOpen(true);
        } finally {
          document.body.removeChild(el);
        }
      }
    } catch (err) {
      setShareMessage(`复制链接失败`);
      setOpen(true);
    }
  };
  const renderSalary = (item: ProjectRequestInfo) => {
    return (
      <span className={classes.projectSalary}>
        {item?.remunerationInfo?.monthlySalaryMin ? (
          <span className={classes.infoValue}>
            {moneyUnit(Number(item?.remunerationInfo.monthlySalaryMin))}
            {item?.remunerationInfo?.monthlySalaryMax
              ? `-${moneyUnit(Number(item?.remunerationInfo.monthlySalaryMax))}`
              : null}
          </span>
        ) : item?.remunerationInfo?.singleSalaryMin ? (
          <span className={classes.infoValue}>
            {moneyUnit(Number(item?.remunerationInfo.singleSalaryMin))}
            {item?.remunerationInfo.singleSalaryMax
              ? `-${moneyUnit(Number(item?.remunerationInfo.singleSalaryMax))}`
              : null}
          </span>
        ) : (
          <span className={classes.infoValue}>
            {item?.remunerationInfo?.otherSalaryDescription}
          </span>
        )}
      </span>
    );
  };

  const getConsultantGetAllProjectBidOnlyId = async () => {
    const value = await dispatch(consultantGetAllProjectBidOnlyId());
    setBidAllRequestId(value);
  };
  useEffect(() => {
    user.id && getConsultantGetAllProjectBidOnlyId();
  }, [user.id]);

  useEffect(() => {
    if (bidAllRequestId.length > 0 && requestId) {
      setAlreadyBid(false);
      const isArr: boolean[] = [];
      bidAllRequestId.map((el: Bidding_info) => {
        el.request?.id == requestId ? isArr.push(true) : isArr.push(false);
      });
      isArr.includes(true) ? setAlreadyBid(true) : setAlreadyBid(false);
    }
  }, [bidAllRequestId]);

  useEffect(() => {
    fetchData();
  }, []);

  // const projectStatus = (
  //   <div className={classes.connect}>
  //     <div className={classes.center}>项目不存在！</div>
  //   </div>
  // );

  const projectDetail = (
    <div className={classes.contentBox}>
      <div className={classes.back}>
        <Button
          type="button"
          variant="contained"
          color="primary"
          onClick={handleApply}
          className={classes.buttonClass}
          style={{ width: '8rem' }}
        >
          <EmojiPeopleIcon />
          <span>申请项目</span>
        </Button>
        <br />
        <Button
          className={classes.buttonClass}
          type="button"
          color="primary"
          onClick={() => window.history.back()}
        >
          <ReplyIcon />
          <span>返回</span>
        </Button>
      </div>
      {/* {!user.consultant?.id && user.id && (
        <Alert severity="warning">你还不是顾问请注册填写顾问信息（在右侧下拉导航个人信息中）</Alert>
      )} */}
      {alreadyBid && user.userType != FUserTypeEnum.COMPANY && (
        <Alert severity="warning">当前项目已经申请不能重复申请!</Alert>
      )}
      <Helmet title={`${projectData?.projectName}-傅通自由职业`}></Helmet>
      <div style={{ textAlign: 'right' }}>
        <span style={{ fontSize: '0.75rem', color: 'rgba(0, 0, 0, 0.5)' }}>分享项目</span>
        <Tooltip title="分享" arrow>
          <IconButton
            className={classes.bgColor}
            size="small"
            color="primary"
            aria-label="delete"
            onClick={() => {
              copyToClipboard();
            }}
          >
            <ShareIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </div>
      <div className={classes.projectTitle}>
        <div style={{ alignItems: 'center', display: 'flex', color: 'rgba(0, 0, 0, 0.8)' }}>
          <BookmarkBorderIcon fontSize="large" color="primary" />
          <div className={classes.projectName}> {projectData?.projectName}</div>
          <span className={classes.spanColor}></span>
          {renderSalary(projectData as ProjectRequestInfo)}
        </div>
      </div>

      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          flexWrap: 'wrap',
        }}
      >
        <div style={{ width: '480px' }}>
          {projectData?.label && (
            <div className={classes.projectLabel}>
              {JSON.parse(projectData?.label.toString()).map((Item: string, index: number) => {
                return (
                  <Chip
                    label={Item}
                    key={index}
                    className={classes.chip}
                    // icon={<BookmarkBorderIcon />}
                    color="primary"
                    variant="outlined"
                  />
                );
              })}
            </div>
          )}

          <div className={classes.textContent}>
            {projectData?.projectStartAsap && <span className={classes.spanColor}>立即开始</span>}
            {projectData?.projectStartTime && (
              <span className={classes.spanColor}>
                {formatDate(new Date(`${projectData?.projectStartTime}`), 'yyyy-MM-dd')}
              </span>
            )}
            {projectData?.projectLongTerm && <span className={classes.spanColor}>长期</span>}
            <span className={classes.spanColor}>
              {workScenario?.remote && !workScenario?.onsite && '远程办公'}&nbsp;
              {projectData?.workLocationCity &&
                projectData?.workLocationDistrict &&
                MatchArea({
                  pCode: '',
                  cCode: projectData?.workLocationCity || '1101',
                  aCode: projectData?.workLocationDistrict || '110101',
                })}
            </span>
            <span className={classes.spanColor}>
              {projectData?.seniority &&
                Seniority.find(item => item.value === projectData?.seniority)?.label}
            </span>
          </div>
          {professionalLabelList && professionalLabelList.length > 0 && (
            <div className={classes.textContent2}>
              {professionalLabelList.map((item, index) => {
                return (
                  <span key={index}>
                    {item}
                    {professionalLabelList.length != index + 1 ? (
                      <span className={classes.spanColor}>|</span>
                    ) : null}
                  </span>
                );
              })}
            </div>
          )}
        </div>
        <div style={{ width: '336px', height: '143px' }}>
          <img
            src={projectData?.projectCover ? projectData.projectCover : ''}
            alt=""
            width="336"
            height="143"
          />
        </div>
      </div>
      <Button
        type="button"
        variant="contained"
        style={{ margin: '0 1rem' }}
        color="primary"
        size="small"
        onClick={handleApply}
      >
        <EmojiPeopleSharpIcon fontSize="small" /> 申请项目
      </Button>

      <Divider className={classes.dLine} />

      <div className={classes.content}>
        <div className={classes.divLevel2}>
          <MoreVertIcon color="primary" />
          <span className={classes.borderBottom}> 项目信息</span>
        </div>
        <div className={classes.wordContent}>
          <div className={classes.secondaryContent}>
            <div className={classes.secondaryTitle}>项目描述：</div>
            {projectData?.projectContent && (
              <div className={classes.projectContent}>
                {projectData?.projectContent?.split('\n').map(item => {
                  return (
                    <span key={item}>
                      {item}
                      <br />
                    </span>
                  );
                })}
              </div>
            )}
          </div>

          <div className={classes.secondaryContent}>
            <div className={classes.secondaryTitle}>
              {industryLabelList && industryLabelList.length > 0 && '行业：'}
            </div>
            {industryLabelList && industryLabelList.length > 0 && (
              <div className={classes.projectContent}>
                {industryLabelList.map((industryItem, industryIndex) => {
                  return (
                    <span key={industryIndex}>
                      {industryItem}
                      {industryLabelList.length != industryIndex + 1 && (
                        <span className={classes.spanColor}>|</span>
                      )}
                    </span>
                  );
                })}
              </div>
            )}
          </div>
          <div className={classes.secondaryContent}>
            <div className={classes.secondaryTitle}>项目时间：</div>
            <div className={classes.projectContent}>
              {projectData?.projectStartAsap && '立即开始'}
              {projectData?.projectStartTime &&
                formatDate(new Date(`${projectData?.projectStartTime}`), 'yyyy-MM-dd')}
              {projectData?.projectEndTime && <span className={classes.spanColor}>-</span>}
              {projectData?.projectEndTime &&
                formatDate(new Date(`${projectData?.projectEndTime}`), 'yyyy-MM-dd')}
              {projectData?.projectLongTerm && <span className={classes.spanColor}>长期</span>}
            </div>
          </div>
          {/* <div>
            创建时间：
            {formatDate(new Date(`${projectData?.createTime}`), 'yyyy-MM-dd')}
          </div> */}
          <div className={classes.secondaryContent}>
            <div className={classes.secondaryTitle}>工作量：</div>
            {projectData?.workload && (
              <div className={classes.projectContent}>
                {projectData?.workload?.split('\n').map(item => {
                  return (
                    <span key={item}>
                      {item}
                      <br />
                    </span>
                  );
                })}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className={classes.content}>
        <div className={classes.divLevel2}>
          <MoreVertIcon color="primary" />
          <span className={classes.borderBottom}> 项目要求</span>
        </div>
        {projectData?.professionalRequirements && (
          <div className={classes.wordContent}>
            <div className={classes.secondaryContent}>
              <div className={classes.secondaryTitle}> 专业性要求:</div>
              <div className={classes.projectContent}>
                {projectData?.professionalRequirements?.split('\n').map(item => {
                  return (
                    <span key={item}>
                      {item}
                      <br />
                    </span>
                  );
                })}
              </div>
            </div>
          </div>
        )}
        {projectData?.educationalRequirements && (
          <div className={classes.wordContent}>
            <div className={classes.secondaryContent}>
              <div className={classes.secondaryTitle}> 学历要求:</div>
              <div className={classes.projectContent}>
                {
                  educationBackgroundRequirements.find(
                    item => item.value === projectData?.educationalRequirements
                  )?.label
                }
              </div>
            </div>
          </div>
        )}
        {projectData?.certificateInfoList &&
          projectData?.certificateInfoList?.length > 0 &&
          projectData?.certificateInfoList[0].certificateName && (
            <div className={classes.wordContent}>
              <div className={classes.secondaryContent}>
                <div className={classes.secondaryTitle}> 技能证书：</div>
                {projectData?.certificateInfoList?.map((e, eIndex) => {
                  return (
                    <div key={eIndex} className={classes.languageInfo}>
                      <div className={classes.inlineBox}>
                        {CertificateTypeArray.find(c => c.value == e.certificateName)?.label}
                        <span className={classes.spanColor}></span>
                        {e.certificateDescription}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        {projectData?.languageInfoList && projectData?.languageInfoList?.length > 0 && (
          <div className={classes.wordContent}>
            <div className={classes.secondaryContent}>
              <div className={classes.secondaryTitle}> 项目语言：</div>
              {projectData?.languageInfoList?.map((lg, lgIndex) => {
                return (
                  <div className={classes.languageInfo} key={lgIndex}>
                    <div className={classes.inlineBox}>
                      {LANGUAGE_WORLD.find(e => e.value === lg.languageName)?.label}
                      <span className={classes.spanColor}></span>
                      {Proficiency_level[lg.languageLevel]}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )}
        {projectData?.otherRequirements && (
          <div className={classes.wordContent}>
            <div className={classes.secondaryContent}>
              <div className={classes.secondaryTitle}> 其他要求:</div>
              <div className={classes.projectContent}>
                {projectData?.otherRequirements?.split('\n').map(item => {
                  return (
                    <span key={item}>
                      {item}
                      <br />
                    </span>
                  );
                })}
              </div>
            </div>
          </div>
        )}
      </div>

      {projectData?.attachmentFileList && projectData?.attachmentFileList?.length > 0 && (
        <div className={classes.content}>
          <div className={classes.divLevel2}>
            <MoreVertIcon color="primary" />
            <span className={classes.borderBottom}>其他信息 </span>
          </div>
          <div className={classes.wordContent}>
            {/* <div>
            项目封面：
            {projectData?.projectCover ? <img src={projectData.projectCover}></img> : '无'}
          </div> */}
            <div>
              项目附件：
              {projectData?.attachmentFileList.map((el, index) => {
                return (
                  <div key={index} className={classes.annexFileCss}>
                    {formatName(el)}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}

      {projectData?.workLocationAddress && (
        <div className={classes.content}>
          <div className={classes.divLevel2}>
            <MoreVertIcon color="primary" />
            <span className={classes.borderBottom}>工作地址 </span>
          </div>
          <div className={classes.workAddress}>{projectData?.workLocationAddress}</div>
        </div>
      )}
      <div className={classes.companyStyle}>
        {projectData?.company?.verification?.basicInfo && (
          <div className={classes.companyDiv}>
            <div className={classes.companyTitle}> 公司信息</div>
            <p></p>
            <Divider />
            <div className={classes.avatarPictureBox}>
              <img
                src={
                  projectData?.company?.verification?.basicInfo?.avatar
                    ? projectData?.company?.verification?.basicInfo?.avatar
                    : picture
                }
                className={classes.avatarPicture}
              ></img>

              {projectData.anonymous !== 'yes'
                ? projectData?.company?.verification?.basicInfo?.alias
                : '**公司'}
            </div>

            <div className={classes.remunerationInfo}>
              公司：
              {projectData.anonymous !== 'yes'
                ? projectData?.company?.verification?.basicInfo?.registrationName
                : '****公司'}
            </div>
            <div className={classes.remunerationInfo}>
              行业：
              {
                companyIndustry.find(
                  industry =>
                    industry.value ===
                    projectData?.company?.verification?.basicInfo?.companyIndustry
                )?.name
              }
            </div>
            <div className={classes.remunerationInfo}>
              {projectData?.company?.verification?.supplementInfo?.businessDescription}
            </div>
          </div>
        )}
      </div>
      <Snackbar
        open={open}
        autoHideDuration={5000}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Alert onClose={handleClose} severity="success">
          {shareMessage}
        </Alert>
      </Snackbar>
      <Dialog
        onClose={handleDialogClose}
        open={dialogOpen}
        maxWidth="lg"
        className={classes.dialogWidth}
      >
        <DialogTitles id="customized-dialog-title" onClose={handleDialogClose}>
          申请项目
        </DialogTitles>
        <DialogContent dividers className={classes.bidingView}>
          <ConsultantAppliesBiddingView
            editData={true}
            onClose={handleDialogClose}
          ></ConsultantAppliesBiddingView>
        </DialogContent>
      </Dialog>
    </div>
  );

  if (!showLoading) {
    return <>{projectDetail}</>;
  }

  return <LoadingLogo />;
};

export interface Props extends WithStyles<typeof styles> {
  className?: string;
  requestId: number;
  examine?: boolean;
}

export default withStyles(styles)(ProjectPreviewView);
